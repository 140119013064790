<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات  ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="info-box">
      <p>
        {{ tipsInfo.description }}
      </p>
      <div class="info-box-detail">
        <h6>تاریخ ثبت : {{ tipsInfo.insertDate }}</h6>
        <h6>تاریخ ویرایش :{{ tipsInfo.updateDate }}</h6>
        <div>
          <a
            v-for="(item, index) of fileName"
            :key="index"
            :href="item"
            target="_blank"
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="reply-box">
      <div class="tips-reply-list">
        <div v-if="tipsReply.length > 0">
          <div v-for="(item, index) of tipsReply" :key="index">
            <div class="reply-info">
              <h5>{{ item.description }}</h5>
              <button @click="replyTips(item.id, item.description)">
                پاسخ به پیام
              </button>
              <a
                v-for="(itemfile, index) of item.files"
                :key="index"
                :href="itemfile"
                ><i class="fa fa-download" aria-hidden="true"></i
              ></a>
            </div>
            <div
              class="reply-info-answer"
              v-for="(itemreply, index) of item.replies"
              :key="index"
            >
              <h5>
                {{ itemreply.description }}
              </h5>
              <button @click="replyTips(item.id, item.description)">
                پاسخ به پیام
              </button>
              <a
                v-for="(itemreplyfile, index) of itemreply.files"
                :key="index"
                :href="itemreplyfile"
                ><i class="fa fa-download" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
        <h5 v-else>هیچ پاسخی ثبت نشده است</h5>
      </div>
      <div class="add-reply">
        <h6 v-if="replyId">
          {{ replyDesc }}
        </h6>
        <textarea
          cols="30"
          rows="10"
          placeholder="متن پیام را وارد کنید ..."
          v-model="description"
        ></textarea>
        <button
          v-c-tooltip="{
            content: 'پاک کردن پیام',
            placement: 'right',
          }"
          @click="clearForm()"
        >
          <i class="fa fa-eraser" aria-hidden="true"></i>
        </button>
        <label
          for="inputTag"
          v-c-tooltip="{
            content: 'پیوست فایل',
            placement: 'right',
          }"
        >
          <i class="fa fa-paperclip" aria-hidden="true"></i>
          {{ fileAttachedCount }}
          <input id="inputTag" type="file" multiple @change="onFileChange" />
        </label>
        <button
          v-c-tooltip="{
            content: 'ثبت و ارسال',
            placement: 'right',
          }"
          @click="submitReply()"
        >
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";

export default {
  name: "tipsMessageForm",
  props: ["tipsId", "farmTitle", "farmId"],
  watch: {},
  components: {},
  data() {
    return {
      loading: false,
      tipsInfo: {
        id: null,
        title: "",
        description: "",
        insertDate: null,
        updateDate: null,
      },
      tipsReply: [],
      fileName: [],
      files: null,
      description: null,
      fileAttachedCount: 0,
      replyId: null,
      replyDesc: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllTipsAndReply", "NewTipsReply"]),
    async GetTipsInfo() {
      this.loading = true;
      var result = await this.GetAllTipsAndReply({
        id: this.tipsId,
      });
      console.log("GetTipsInfo", result);
      this.loading = false;
      if (result.succeeded) {
        this.tipsInfo = {
          id: result.data.id,
          title: result.data.title,
          description: result.data.description,
          insertDate: result.data.insertDate,
          updateDate: result.data.updateDate,
        };
        this.tipsReply = result.data.tipsReplies;
        result.data.files.forEach((element) => {
          this.fileName.push(`${apiUrlRoot}/TipsFile/${element}`);
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    async submitReply() {
      let formData = new FormData();
      formData.append("tipsId", this.tipsId);
      formData.append("farmId", this.farmId);
      formData.append("childId", this.replyId);
      formData.append("description", this.description);
      if (this.files > 0) {
        this.files.forEach((element) => {
          formData.append("Files", element);
        });
      } else {
        formData.append("Files", null);
      }
      let newTips = await this.NewTipsReply({
        tipsReply: formData,
      });
      console.log("newTips", newTips);
      if (newTips) {
        this.clearForm();
        this.$emit("closeModal", true);
        this.$notify({
          title: "پیام سیستم",
          text: ` ثبت دستورالعمل انجام شد <br /><br />`,
          type: "success",
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `خطا در ثبت دستورالعمل  <br /><br />`,
          type: "error",
        });
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileAttachedCount = files.length;
      this.files = files;
    },
    clearForm() {
      this.files = null;
      this.description = null;
      this.fileAttachedCount = 0;
    },
    replyTips(id, desc) {
      this.replyId = id;
      this.replyDesc = desc;
    },
  },
  mounted() {
    this.GetTipsInfo();
  },
};
</script>

<style scoped>
.main-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-box {
  display: flex;
  flex-direction: column;
  margin: 11px;
  background-color: #cfd8dc;
  padding: 5px;
  border-radius: 5px;
  gap: 15px;
}
.info-box-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background-color: #eceff1;
  padding: 4px;
}
.info-box-detail h6 {
  color: #1976d2;
  font-size: 12px;
  font-weight: bold;
}
.info-box-detail > div {
  margin-right: auto;
  margin-left: 5px;
}
.info-box-detail > div a {
  margin-left: 5px;
}
.reply-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: stretch;
}
.tips-reply-list {
  width: 100%;
  height: 40vh;
  overflow-y: auto;
}
.tips-reply-list > h5 {
  width: 100%;
  text-align: center;
  margin-top: 6em;
  color: #ababab;
  font-family: "Koodak";
  font-size: 1.7em;
}
.reply-info {
  width: 95%;
  height: fit-content;
  background-color: #ec53b0;
  margin: 2% 5px;
  padding: 0.5em;
  border-radius: 19px;
  color: #fff;
}
.reply-info button {
  float: left;
  width: fit-content;
  clear: left;
  background-color: #f985cc;
  color: #fff;
  border: none;
  border-radius: 13px;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.2em 1.4em;
}
.reply-info h5 {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 0.8em;
  font-weight: normal;
  padding: 0.6em;
}
.reply-info a {
  float: right;
  margin: 0.2em;
  color: #fff;
}
.reply-info-answer {
  width: 95%;
  height: fit-content;
  float: left;
  clear: both;
  background-color: #344eff;
  margin: 2% 5px;
  padding: 0.5em;
  border-radius: 19px;
  color: #fff;
}
.reply-info-answer button {
  float: right;
  width: fit-content;
  clear: right;
  background-color: #5d71fd;
  color: #fff;
  border: none;
  border-radius: 13px;
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.2em 1.4em;
}
.reply-info-answer h5 {
  float: right;
  width: 100%;
  text-align: right;
  font-size: 0.8em;
  font-weight: normal;
  padding: 0.6em;
}
.reply-info-answer a {
  float: left;
  margin: 0.2em;
  color: #fff;
}

.add-reply {
  width: 100%;
}
.add-reply input[type="file"] {
  display: none;
}
.add-reply textarea {
  float: right;
  width: 85%;
  height: 17vh;
  margin-right: 1%;
  border: 1px solid #bbb;
  resize: none;
  outline: none;
  padding: 0.5em;
}
.add-reply button {
  float: left;
  clear: left;
  width: 4vw;
  height: 4vh;
  border: none;
  background-color: rgb(89, 255, 0);
  margin: 0.5em;
  border-radius: 30px;
  margin-left: 1em;
  font-size: 1em;
  border: 2px solid rgb(89, 255, 0);
}
.add-reply button:hover {
  background-color: #fff;
  color: darkgreen;
  border: 2px solid rgb(89, 255, 0);
}
.add-reply label {
  float: left;
  clear: left;
  width: 4vw;
  height: 4vh;
  border: none;
  background-color: rgb(89, 255, 0);
  margin: 0.5em;
  border-radius: 30px;
  margin-left: 1em;
  font-size: 1em;
  border: 2px solid rgb(89, 255, 0);
  cursor: pointer;
  text-align: center;
}
.add-reply label i {
  font-size: 1.5em;
  color: #000;
  margin-top: 0.3em;
}
.add-reply label:hover {
  background-color: #fff;
  color: darkgreen;
  border: 2px solid rgb(89, 255, 0);
}

.add-reply h6 {
  width: 100%;
  text-align: right;
  font-size: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
