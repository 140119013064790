<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      text="دریافت اطلاعات  ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <input
      type="text"
      class="main-box-input"
      v-model="title"
      placeholder="عنوان دستورالعمل را وارد کنید ... "
    />
    <label class="file-uploaded-box" for="inputTag" v-if="tipsId == 0">
      <i class="fa fa-paperclip" aria-hidden="true"></i>
      {{ fileAttached }}
      <input id="inputTag" type="file" multiple @change="onFileChange" />
    </label>
    <textarea
      class="main-box-input-area"
      v-model="description"
      placeholder="توضیحات دستورالعمل را وارد کنید ..."
    ></textarea>
    <div class="file-uploaded-name" style="grid-area: detail">
      <ul>
        <li
          v-if="fileName.length == 0"
          style="text-align: center; color: #fff; background-color: #455a64"
        >
          هیچ فایلی پیوست نشده است
        </li>
        <li v-else-if="fileName.length > 0 && tipsId > 0">
          <div v-for="(item, index) of fileName" :key="index">
            <a v-if="tipsId > 0" :href="item.name" target="_blank">
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
            <i
              v-if="tipsId > 0"
              @click="deleteFile(item.id, index)"
              class="fa fa-trash"
              aria-hidden="true"
            ></i>
          </div>
        </li>
        <li v-else>
          <div v-for="(item, indexs) of fileName" :key="indexs">{{ item }}</div>
        </li>
      </ul>
    </div>
    <CButton
      class="flex-grow-1"
      color="success"
      style="width: 100%; grid-area: action"
      @click="submitTips()"
    >
      {{ tipsId == 0 ? "ثبت دستورالعمل" : "ویرایش دستورالعمل" }}
    </CButton>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../../constants/config";

export default {
  name: "newTipsForm",
  props: ["farmId", "farmTitle", "tipsId"],
  watch: {
    tipsId: function () {
      this.featchData(this.tipsId);
    },
  },
  components: {},
  data() {
    return {
      loading: false,
      tipsList: [],
      fileAttached: "پیوست فایل به دستورالعمل ...",

      title: null,
      description: null,
      files: null,
      insertDate: null,
      fileName: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["NewTips", "EditTips", "GetTips", "DeleteTipsFile"]),
    async featchData(id) {
      this.loading = true;
      let tips = await this.GetTips({
        id: id,
      });
      this.loading = false;
      if (tips.succeeded) {
        this.title = tips.data.title;
        this.description = tips.data.description;
        this.insertDate = tips.data.InsertDate;
        tips.data.files.forEach((element) => {
          this.fileName.push({
            id: element.item1,
            name: `${apiUrlRoot}/TipsFile/${element.item2}`,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${tips.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    getCurrentDateTime() {
      let options = { year: "numeric", month: "long", day: "numeric" };
      return new Date().toLocaleDateString("fa-IR", options);
    },
    async submitTips() {
      this.loading = true;
      if (this.tipsId > 0) {
        let updateModel = {
          id: this.tipsId,
          title: this.title,
          description: this.description,
        };
        let updateTips = await this.EditTips({
          editTips: updateModel,
        });
        if (updateTips) {
          this.$emit("closeModal", false);
          this.$notify({
            title: "پیام سیستم",
            text: `ویرایش دستورالعمل انجام شد <br /><br />`,
            type: "success",
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در ویرایش دستورالعمل  <br /><br />`,
            type: "error",
          });
        }
      } else {
        let formData = new FormData();
        formData.append("id", this.tipsId);
        formData.append("farmId", this.farmId);
        formData.append("title", this.title);
        formData.append("description", this.description);
        if (this.files) {
          this.files.forEach((element) => {
            formData.append("Files", element);
          });
        }
        let newTips = await this.NewTips({
          tips: formData,
        });
        if (newTips) {
          this.$emit("closeModal", false);
          this.$notify({
            title: "پیام سیستم",
            text: ` ثبت دستورالعمل انجام شد <br /><br />`,
            type: "success",
          });
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: `خطا در ثبت دستورالعمل  <br /><br />`,
            type: "error",
          });
        }
      }
      this.loading = false;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.fileAttached = `${files.length} فایل پیوست شده است`;
      this.files = files;
      this.files.forEach((element) => {
        this.fileName.push(element.name);
      });
    },
    async deleteFile(id, index) {
      this.$confirm({
        message: `فایل پیوست حذف شود؟`,
        button: {
          no: "لغو",
          yes: "بله",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.loading = true;
            let deleteRow = await this.DeleteTipsFile({
              id: id,
            });
            this.$emit("closeModal", true);
            this.loading = false;
            if (deleteRow.succeeded) {
              this.fileName.splice(index, 1);
              this.$notify({
                title: "پیام سیستم",
                text: `حذف فایل انجام شد <br /><br />`,
                type: "success",
              });
            } else {
              this.$notify({
                title: "پیام سیستم",
                text: `خطا در حذف فایل  <br /><br />`,
                type: "error",
              });
            }
          }
        },
      });
    },
  },
  mounted() {
    if (this.tipsId > 0) {
      this.featchData(this.tipsId);
    } else {
      this.insertDate = this.getCurrentDateTime();
    }
  },
  created() {},
};
</script>

<style scoped>
.main-box {
  width: 100%;
  display: grid;
  height: 50vh;
  grid-template-areas:
    "header header header header header header-file"
    "body body body body body detail"
    "action action action action action action";
  gap: 10px;
}
.main-box-input {
  grid-area: header;
  flex-grow: 1;
  border-radius: 5px;
  padding: 0.6em 0.5em;
  outline: none;
  resize: none;
  border: none;
  border: 1px solid #d7d7d7;
}
.main-box-input-area {
  grid-area: body;
  flex-grow: 1;
  border-radius: 5px;
  padding: 0em 0.5em;
  outline: none;
  resize: none;
  border: none;
  border: 1px solid #d7d7d7;
  height: 39vh;
  margin: 0px;
}
input[type="file"] {
  display: none;
}

.file-uploaded-box {
  grid-area: header-file;
  cursor: pointer;
  background-color: #43a047;
  color: #fff;
  border-radius: 5px;
  text-align: right;
  border: 1px solid #43a047;
  -webkit-transition: all 500ms;
  transition: all 500ms;
  padding: 5px 10px;
  margin: 0px;
}
.file-uploaded-box:hover {
  color: #43a047;
  background-color: #fff;
}
.file-uploaded-box:hover i {
  color: #fff;
  background-color: #43a047;
}
.file-uploaded-box i {
  font-size: 1.6em;
  background-color: #43a047;
}

.file-uploaded-name {
  background-color: whitesmoke;
  clear: both;
  border: 1px solid #bbb;
  padding-top: 0.4em;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.file-uploaded-name ul {
  margin: 0px;
  padding: 0px;
}
.file-uploaded-name ul li {
  list-style: none;
  box-shadow: 0px 3px 12px -5px #000;
  background-color: #fff;
  border-radius: 3px;
  margin: 4px;
  text-align: right;
}
.file-uploaded-name ul li a {
  width: 77%;
  text-align: right;
  padding: 0.1em 0em 0em 0em;
  color: green;
  margin: 0.3em 0.4em;
  height: 2vh;
  cursor: pointer;
}
.file-uploaded-name ul li > i {
  width: 77%;
  text-align: right;
  padding: 0.1em 0em 0em 0em;
  background-color: #e55353;
  color: #fff;
  margin: 0em 0.4em;
  height: 2vh;
  cursor: pointer;
}
</style>
