<template>
  <div>
    <vue-element-loading
      :active="loading"
      text="دریافت لیست دستورالعمل ها ... "
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <no-data
      show
      :message="`هیچ توصیه ای ثبت نشده است`"
      v-if="tipsList.length == 0"
      style="margin-top: 20dvh"
    >
    </no-data>
    <div class="tips-list-item" v-else>
      <div class="tips-item" v-for="(item, index) of tipsList" :key="index">
        <div class="tips-item-info">
          <ul>
            <li style="border-bottom: none">
              <h3
                style="
                  text-align: center;
                  color: #4caf50;
                  font-weight: bold;
                  font-size: 16px;
                "
              >
                {{ item.title }}
              </h3>
            </li>
            <li>
              <h4>زمین</h4>
              <h3>{{ item.farmName }}</h3>
            </li>
            <li>
              <h4>تاریخ ثبت</h4>
              <h3>{{ item.insertDate }}</h3>
            </li>
            <li>
              <h4>تاریخ ویرایش</h4>
              <h3>{{ item.updateDate }}</h3>
            </li>
            <li>
              <h4>تعداد پاسخ</h4>
              <h3>{{ item.replyCount }} پاسخ</h3>
            </li>
          </ul>
        </div>
        <p class="tips-item-text">{{ item.description }}</p>
        <div class="tips-item-action">
          <a
            target="_blank"
            v-for="(f, i) of item.files"
            :key="i"
            :href="downloadLink(f)"
            >دانلود فایل پیوست {{ i + 1 }}</a
          >
          <CButton
            color="success"
            @click="tipsReply(item.id, item.title)"
            v-if="farmId > 0"
          >
            پاسخ های توصیه
          </CButton>
          <CButton
            color="info"
            @click="newTips(item.id)"
            v-if="farmId > 0 && item.currentUser"
          >
            ویرایش
          </CButton>
        </div>
      </div>
    </div>
    <VueModal
      :title="modelTitle"
      v-model="modelState"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      modal-style="max-width: 60dvw !important;"
      :enable-close="true"
    >
      <component
        v-if="subForm != null"
        @closeModal="refreshList"
        v-bind:is="subForm"
        :farmId="farmId"
        :farmTitle="farmName"
        :tipsId="tipsId"
      />
    </VueModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tipsItem from "./tipsItem";
import tipsMessage from "./tipsMessage";
import { apiUrlRoot } from "../../../constants/config";
import { getCurrentUser } from "../../../utils/index";
import NoData from "../../../components/no-data.vue";

export default {
  name: "tipsForm",
  props: ["farmId", "farmerId", "farmName"],
  watch: {
    farmerId: function () {
      this.GetAllTipsList();
    },
    farmId: function () {
      this.GetAllTipsList();
    },
  },
  components: {
    NoData,
    tipsItem,
    tipsMessage,
  },
  data() {
    return {
      loading: false,
      modelTitle: "",
      modelState: false,
      tipsList: [],
      subForm: null,
      tipsId: 0,
    };
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllTips"]),
    async GetAllTipsList() {
      this.loading = true;
      this.tipsList = [];
      let tipsResult = await this.GetAllTips({
        farmerId: this.farmerId,
        farmId: this.farmId,
      });
      this.loading = false;
      if (tipsResult.succeeded) {
        this.tipsList = tipsResult.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${tipsResult.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    newTips(id) {
      this.modelTitle =
        id == 0
          ? `ثبت توصیه اختصاصی جدید برای زمین ${this.farmName}`
          : `ویرایش توصیه اختصاصی ثبت شده برای زمین ${this.farmName}`;
      this.subForm = "tipsItem";
      this.modelState = true;
      this.tipsId = id;
    },
    tipsReply(id, title) {
      this.modelTitle = `لیست پیام های ${title}`;
      this.subForm = "tipsMessage";
      this.id = this.farmId;
      this.modelState = true;
      this.tipsId = id;
    },
    refreshList(state) {
      this.GetAllTipsList();
      this.modelState = state;
    },
    downloadLink(fileName) {
      return `${apiUrlRoot}/TipsFile/${fileName}`;
    },
  },
  mounted() {
    this.GetAllTipsList();
  },
};
</script>

<style scoped>
.tips-list-item {
  height: 79vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 21px 0px;
}
.tips-item {
  margin: 0.5em;
  display: grid;
  grid-template-areas:
    "info info info text text text"
    "info info info text text text"
    "info info info action action action";
  gap: 10px;
  box-shadow: 0px 4px 9px -3px #000;
  border-radius: 5px;
}

.tips-item-info {
  grid-area: info;
  margin: 7px;
  background-color: #eceff1;
  padding: 10px;
}
.tips-item-info ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tips-item-info ul li {
  margin: 0px;
  border-bottom: 1px dashed #90a4ae;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin: 4px 1px;
  padding: 2px 0px;
}
.tips-item-info ul li:last-child {
  border-bottom: none;
}
.tips-item-info ul li h4 {
  font-size: 13px;
  margin: 0px 2px;
  color: #37474f;
  font-weight: bold;
  width: 5vw;
}
.tips-item-info ul li h3 {
  font-size: 14px;
  margin: 0px;
  flex-grow: 1;
  margin: 0px 11px;
  color: #ff5722;
}

.tips-item-text {
  grid-area: text;
  margin: 6px;
  padding: 4px;
  font-size: 14px;
  color: #37474f;
}
.tips-item-action {
  grid-area: action;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 6px;
  gap: 10px;
}
.tips-item-action a {
  background-color: #607d8b;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
